import * as React from "react"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles"
import ContactForm from "../components/forms/contactForm"
const useStyles = makeStyles({
  topcontainer: {
    marginTop: 60
  },
  gradientText: {
    fontSize: "30vw",
    background: "-webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  }
})

const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <Container className={classes.topcontainer}>
        <Typography
          className={classes.gradientText}
          variant="h1"
          align="center"
        >
          404
        </Typography>
        <Typography variant="h5" align="center">
          Sorry, this page is missing😕
        </Typography>
      </Container>
      <ContactForm/>
    </Layout>
  )
}

export default NotFoundPage
