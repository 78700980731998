import React from 'react'
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button"




const ContactForm = (props)=>{
    return(
        <>
        <div>
            <form name="contact" method="POST" data-netlify="true">
            <TextField
            autoComplete={true}
            fullWidth={true}
            color="secondary"
            label="Email Address"
            name="Email"
            variant='outlined'
            margin='dense'
            />
            <TextField
            autoComplete={true}
            fullWidth={true}
            color="secondary"
            label="First Name"
            name="First Name"
            variant='outlined'
            margin='dense'
            />
            <TextField
            autoComplete={true}
            fullWidth={true}
            color="secondary"
            label="Last Name"
            name="Last Name"
            variant='outlined'
            margin='dense'
            />
            <TextField
            autoComplete={true}
            fullWidth={true}
            color="secondary"
            label="Message"
            name="message"
            variant='outlined'
            margin='dense'
            multiline={true}
            rows={10}
            />
            <Button type="submit">
                Send
            </Button>






            </form>
        </div>
        </>
    )
}

export default ContactForm